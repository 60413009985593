
@import url('https://fonts.googleapis.com/css2?family=Golos+Text:wght@600&display=swap');

* {
  margin: 0;
  box-sizing: border-box;
  color: white;
}

.landingpage {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: relative;
  transition: 0.5s;
  font-family: "Merianda";
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.video-bg {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  z-index: -1;
  object-fit: cover;
}
.home-text {
  width: 100%;
  height: 270px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  z-index: 2;
  text-align: center;
}
@media only screen and (max-width: 600px) {
  .home-text {
    width: 80%;
    font-size: 10px;
    text-align: center;
  }
}

@media only screen and (max-width: 442px) {
  .home-text {
    width: 80%;
    font-size: 8px;
    text-align: center;
  }
}
h1 {
  font-size: 6em;
  animation-name: fade;
  animation-duration: 4s;
  text-transform: uppercase;
  font-family: 'Golos', sans-serif;

  animation: 2s fadeIn;
  animation-fill-mode: both;
  
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}

p {
  justify-self: flex-end;
  font-size: 2em;
  font-family: 'Golos', sans-serif;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
